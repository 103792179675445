<template>
  <div class="auth-container-wrapper">
    <div class="header">
      <div class="left">
        <Breadcrumb v-if="isEdit">
          <BreadcrumbItem @click="goSubAccountPage" to="/system/sub_account">
            {{ $t('zi-zhang-hao-guan-li') }}
          </BreadcrumbItem>
          <BreadcrumbItem>{{ $t('shu-ju-ku-shou-quan') }}<span v-if="$route.query.name">（{{ $route.query.name }}）</span>
          </BreadcrumbItem>
        </Breadcrumb>
        <Breadcrumb v-else>
        <BreadcrumbItem>{{ $t('wo-de-quan-xian') }}</BreadcrumbItem>
      </Breadcrumb>
      </div>
      <div class="right">
        <Button v-if="isEdit" @click="handleSwitchBatchMode" style="margin-right: 10px;">
          {{ batchMode ? $t('tui-chu-pi-liang-shou-quan') : $t('pi-liang-shou-quan') }}
        </Button>
        <Button @click="handleReloadPage">{{$t('shua-xin')}}</Button>
      </div>
    </div>
    <div class="auth-content">
      <div class="auth-container">
        <div class="auth">
          <div class="left">
            <div class="auth-btns">
              <a-button :type="activeAuthTab === authTab.value ? 'primary' : 'default'" :disabled="disableAuthTab(authTab.value)"
                        v-for="authTab in authTabs" :key="authTab.value" class="auth-btn"
                        @click="handleSwitchAuth(authTab.value, authTab.type)"
              >
                {{ authTab.label }}
              </a-button>
            </div>
            <div v-if="activeAuthTab === 'DataJob' && getCcProductClusterList.length > 0">
              <a-select v-model="selectedCcCluster" style="width: 100%;" @change="handleChangeCcCluster">
                <a-select-option :value="cluster.clusterCode" :key="cluster.id" v-for="cluster in getCcProductClusterList">{{ cluster.clusterDesc }}</a-select-option>
              </a-select>
            </div>
            <div class="search">
              <a-select v-if="isEdit" v-model="datasourceTreeSearchType" style="width: 100px;" @change="handleSearchTypeChange">
                <a-select-option value="all">{{ $t('quan-bu') }}</a-select-option>
                <a-select-option value="authed">{{ $t('yi-shou-quan') }}</a-select-option>
                <a-select-option value="unAuth">{{ $t('wei-shou-quan') }}</a-select-option>
              </a-select>
              <a-input-search
                allow-clear
                v-model="datasourceTreeSearchKey"
                @change="handleDataSourceSearch"/>
            </div>
            <c-tree
              ref="dataSourceTree"
              keyField="key"
              :emptyText="$t('zan-wu-shu-ju')"
              :render="renderNode"
              @check="handleDataSourceTreeSelect"
              :disabled="!isEdit"
              :class="`datasource-tree ${noIndent ? 'no-indent' : ''}`"
              :checkable="batchMode && !previewMode"
              :unselect-on-click="false"
              @click="handleDataSourceTreeSelect"
            />
          </div>
          <div :class="`middle ${showAuthTree ? '' : 'no-auth'}`">
            <div class="setting" v-if="showAuthTree">
              <section>
                <div class="label">{{ $t('shou-quan-shi-jian') }}</div>
                <div class="content">
                  <div class="ranges" v-if="isEdit">
                    <a-button size="small" v-for="range in ranges" :key="range.label"
                              @click="handleRangeChange(range)"
                              :type="range.label===selectedRange.label ? 'primary' : 'default'"
                              style="margin-right: 3px;">{{ range.label }}
                    </a-button>
                  </div>
                  <div class="time">
                    <a-date-picker
                      v-model="authStartTime"
                      :disabled-date="disabledStartDate"
                      show-time
                      size="small"
                      :disabled="!isEdit"
                      format="YYYY-MM-DD HH:mm:ss"
                      :placeholder="$t('kai-shi-shi-jian')"
                      @change="handleStartTimeChange"
                    />
                    ~
                    <a-date-picker
                      v-model="authEndTime"
                      :disabled-date="disabledEndDate"
                      show-time
                      :disabled="!isEdit"
                      size="small"
                      format="YYYY-MM-DD HH:mm:ss"
                      :placeholder="$t('jie-shu-shi-jian')"
                      @change="handleEndTimeChange"
                    />
                  </div>
                </div>
              </section>
            </div>
            <div class="auth-tree-container" v-show="showAuthTree">
              <a-input-search class="search" :loading="auth.loading" @search="handleAuthSearch"
                              allow-clear v-model="auth.searchKey" @change="handleAuthSearch"/>
              <a-spin class="auth-loading" v-if="loadingAuth"/>
              <c-tree
                :emptyText="$t('zan-wu-shu-ju')"
                class="auth-tree"
                :render="renderAuthNode"
                ref="authTree"
                keyField="key"
                checkable
                titleField="i18nName"
                :defaultExpandAll="true"
              />
            </div>
          </div>
          <div class="right"></div>
        </div>
        <div class="footer" v-if="$route.params.uid">
          <a-button @click="handlePreview" type="primary" style="margin-right: 10px;"
                    v-if="!previewMode && isEdit">
            {{ $t('yu-lan') }}
          </a-button>
          <a-button type="primary" @click="handleModifyUserDsAuth" v-if="previewMode"
                    style="margin-right: 10px;">{{ $t('ti-jiao-shou-quan') }}
          </a-button>
          <a-button @click="handleCancelPreviewMode('cancel')" v-if="previewMode" style="margin-right: 10px;">
            {{ $t('ji-xu-shou-quan') }}
          </a-button>
          <a-button @click="handleGoAuth" v-if="isView" style="margin-right: 10px;">
            {{ $t('qu-shou-quan') }}
          </a-button>
          <a-button @click="goSubAccountPage" v-if="!previewMode">
            {{ $t('fan-hui-zi-zhang-hao-lie-biao') }}
          </a-button>
        </div>
      </div>
    </div>
    <a-modal v-model="showAuthedTreeModal" v-if="showAuthedTreeModal"
             :title="$t('shou-quan-que-ren')" :width="800">
      <div class="show-authed-tree-modal">
        <div class="left"></div>
        <div class="right"></div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment';
import CTree from '@wsfe/ctree';
import deepClone from 'lodash.clonedeep';
import { mapGetters, mapState } from 'vuex';
import { JOB_TYPE_ICON } from '@/const';
import {
  listElementsOfLevelForCC,
  listUserAuthOfResForCC,
  listUserAuthResForCC,
  listMyAuthOfResForCC,
  listMyAuthResForCC,
  modifyUserAuthForCC
} from '@/services/cc/api/resAuth';
import i18n from '@/i18n';

export default {
  name: 'MyAuth',
  components: {
    CTree
  },
  data() {
    return {
      selectedCcCluster: '',
      authedData: {},
      showAuthedTreeModal: false,
      batchMode: false,
      previewMode: false,
      noIndent: false,
      uid: '',
      isEdit: false,
      isView: false,
      loadingAuth: false,
      activeAuthTab: 'DataSource',
      activeAuthType: 'datasource',
      authTabs: [
        { label: i18n.t('shu-ju-yuan'), value: 'DataSource', type: 'datasource' },
        { label: i18n.t('ren-wu'), value: 'DataJob', type: 'task' }
      ],
      ranges: [
        {
          label: i18n.t('jin-tian'),
          startTime: moment(),
          endTime: moment().endOf('day')
        },
        {
          label: i18n.t('ben-zhou'),
          startTime: moment(),
          endTime: moment().endOf('week')
        },
        {
          label: i18n.t('ben-yue'),
          startTime: moment(),
          endTime: moment().endOf('month')
        },
        {
          label: i18n.t('jin-nian'),
          startTime: moment(),
          endTime: moment().endOf('year')
        },
        {
          label: i18n.t('yi-tian'),
          startTime: moment(),
          endTime: moment().add(1, 'd')
        },
        {
          label: i18n.t('yi-zhou'),
          startTime: moment(),
          endTime: moment().add(1, 'w')
        },
        {
          label: i18n.t('yi-ge-yue'),
          startTime: moment(),
          endTime: moment().add(1, 'M')
        },
        {
          label: i18n.t('yi-nian'),
          startTime: moment(),
          endTime: moment().add(1, 'y')
        }
      ],
      selectedRange: {},
      datasource: {
        batchCheckedKeys: [],
        treeData: [],
        stashTreeData: [],
        originalTreeData: [],
        searchType: 'all',
        searchKey: '',
        loading: false,
        selectedNode: null
      },
      task: {
        batchCheckedKeys: [],
        treeData: [],
        stashTreeData: [],
        originalTreeData: [],
        searchKey: '',
        searchType: 'all',
        loading: false,
        selectedNode: null
      },
      authList: {},
      subAccount: {
        searchKey: '',
        selectedNode: '',
        treeData: []
      },
      auth: {
        checkedKeys: [],
        startTime: null,
        endTime: null,
        originalTreeData: [],
        batchTreeData: [],
        diffuse: false,
        treeData: [],
        searchKey: '',
        loading: false
      }
    };
  },
  computed: {
    ...mapGetters([
      'includesDM',
      'includesCC'
    ]),
    ...mapState(['userInfo', 'globalSetting', 'productClusterList']),
    getCcProductClusterList() {
      const ccList = [];
      this.productClusterList.forEach((cluster) => {
        if (cluster.product === 'CloudCanal') {
          ccList.push(cluster);
        }
      });
      return ccList;
    },
    datasourceTreeSearchKey: {
      get() {
        return this.activeAuthType === 'datasource' ? this.datasource.searchKey : this.task.searchKey;
      },
      set(value) {
        if (this.activeAuthType === 'datasource') {
          this.datasource.searchKey = value;
        } else {
          this.task.searchKey = value;
        }
      }
    },
    datasourceTreeSearchType: {
      get() {
        return this.activeAuthType === 'datasource' ? this.datasource.searchType : this.task.searchType;
      },
      set(value) {
        if (this.activeAuthType === 'datasource') {
          this.datasource.searchType = value;
        } else {
          this.task.searchType = value;
        }
      }
    },
    showAuthTree() {
      if (this.activeAuthType === 'datasource') {
        return this.datasource.selectedNode || this.batchMode;
      } else {
        return this.task.selectedNode || this.batchMode;
      }
    },
    authStartTime: {
      get() {
        return this.batchMode ? this.auth.startTime : this.activeAuthType === 'datasource' ? this.datasource.selectedNode.startTime : this.task.selectedNode.startTime;
      },
      set(value) {
        if (this.batchMode) {
          this.auth.startTime = value;
        } else {
          if (this.activeAuthType === 'datasource') {
            this.datasource.selectedNode.startTime = value;
          } else {
            this.task.selectedNode.startTime = value;
          }
        }
      }
    },
    authEndTime: {
      get() {
        return this.batchMode ? this.auth.endTime : this.activeAuthType === 'datasource' ? this.datasource.selectedNode.endTime : this.task.selectedNode.endTime;
      },
      set(value) {
        if (this.batchMode) {
          this.auth.endTime = value;
        } else {
          if (this.activeAuthType === 'datasource') {
            this.datasource.selectedNode.endTime = value;
          } else {
            this.task.selectedNode.endTime = value;
          }
        }
      }
    },
    disableAuthTab() {
      return (auth) => {
        let disable = false;
        if (this.previewMode) {
          return true;
        }
        console.log('auth', auth);
        if (!this.includesCC && auth === 'DataJob') {
          disable = true;
        }
        return disable;
      };
    }
  },
  watch: {
    '$route.query.type': {
      handler(newVal) {
        this.initData();
      },
      deep: true,
      immediate: true
    },
    '$route.params.uid': {
      async handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.uid = this.isEdit || this.isView ? this.$route.params.uid : this.userInfo.uid;
          this.subAccount = this.isEdit || this.isView ? this.$route.query.name : '';
          this.emptyAuthData();
          await this.listLevels();
        }
      },
      deep: true
    }
  },
  methods: {
    handleReloadPage() {
      Object.assign(this.$data, this.$options.data());
      this.initData();
    },
    async initData() {
      this.isEdit = this.$route.query.type === 'edit';
      this.isView = this.$route.query.type === 'view';
      this.uid = this.isEdit || this.isView ? this.$route.params.uid : this.userInfo.uid;
      this.subAccount = this.isEdit || this.isView ? this.$route.query.name : '';
      this.activeAuthTab = 'DataSource';
      this.activeAuthType = 'datasource';
      this.emptyAuthData();
      await this.listLevels();
    },
    async handleUserTreeSelect(node) {
      await this.handleGetPreviewData();
      if (this.datasource.authedTreeData.length || this.task.authedTreeData.length) {
        this.$Modal.confirm({
          title: this.$t('ti-shi'),
          content: this.$t('dang-qian-yong-hu-you-wei-ti-jiao-de-shou-quan')
        });
      } else {
        await this.$router.replace({
          params: { ...this.$route.params, uid: node.uid },
          query: { ...this.$route.query, name: node.subAccount }
        });
        await this.$refs.userTree.setSelected(node.key, true);
      }
    },
    async getSubAccountList(data = {}) {
      const res = await this.$services.getSubAccountList({ data });
      if (res.success && res.data) {
        this.subAccount.treeData = res.data.map((node) => {
          node.key = node.uid;
          node.title = node.username;
          return node;
        });
        await this.$refs.userTree.setData(this.subAccount.treeData);
        await this.$refs.userTree.setSelected(this.$route.params.uid, true);
      }
    },
    emptyAuthData() {
      this.datasource = {
        treeData: [],
        originalTreeData: [],
        authedTreeData: [],
        searchType: 'all',
        searchKey: '',
        loading: false,
        selectedNode: null
      };
      this.task = {
        treeData: [],
        originalTreeData: [],
        authedTreeData: [],
        searchType: 'all',
        searchKey: '',
        loading: false,
        selectedNode: null
      };
      this.auth = {
        checkedKeys: [],
        startTime: null,
        endTime: null,
        originalTreeData: [],
        batchTreeData: [],
        diffuse: false,
        treeData: [],
        searchKey: '',
        loading: false
      };
    },
    async handleCancelPreviewMode(type) {
      this.previewMode = false;
      this.isEdit = true;

      if (type === 'refresh') {
        const setPreDsAuthKinds = (treeData) => {
          if (treeData && treeData.length) {
            treeData.forEach((node) => {
              node.preDsAuthKinds = deepClone(node.dsAuthKinds);
              node.preStartTime = node.startTime;
              node.preEndTime = node.endTime;
              if (node.startTime) {
                node.preStartTime = moment(node.startTime).format('YYYY-MM-DD HH:mm:ss');
              }
              if (node.endTime) {
                node.preEndTime = moment(node.endTime).format('YYYY-MM-DD HH:mm:ss');
              }
              setPreDsAuthKinds(node.children);
            });
          }
        };

        setPreDsAuthKinds(this.datasource.treeData);
        setPreDsAuthKinds(this.task.treeData);
      } else {
        this.datasource.treeData = this.datasource.stashTreeData;
        this.task.treeData = this.task.stashTreeData;
      }

      this.datasource.stashTreeData = [];
      this.task.stashTreeData = [];

      this.datasource.authedTreeData = [];
      this.task.authedTreeData = [];

      this.authedData = [];
      this.datasource.searchKey = '';
      this.task.searchKey = '';
      this.$refs.dataSourceTree.setData(this[this.activeAuthType].treeData);
      if (this.batchMode && type === 'cancel') {
        this.$refs.dataSourceTree.setCheckedKeys(this.auth.checkedKeys, true);
      }
      this[this.activeAuthType].selectedNode.addAuth = [];
      this[this.activeAuthType].selectedNode.deleteAuth = [];
      if (this[this.activeAuthType].treeData.length > 0) {
        const firstNode = this[this.activeAuthType].treeData[0];
        this[this.activeAuthType].selectedNode = firstNode;
        this.$refs.dataSourceTree.setSelected(firstNode.key, true);
        await this.handleGetAuthTree(firstNode.objType);
      }
    },
    arrayEqual(a, b) {
      return a.length === b.length && a.sort().toString() === b.sort().toString();
    },
    async handleGetPreviewData() {
      if (this.batchMode) {
        const selectedKeys = this.$refs.dataSourceTree.getCheckedKeys();
        this.auth.checkedKeys = selectedKeys;
        selectedKeys.forEach((key) => {
          this.setCurrentNodeAuthKey(key);
        });
      } else {
        this.setCurrentNodeAuthKey();
      }
      const dsTreeData = this.datasource.treeData;
      console.log(dsTreeData.length);
      const taskTreeData = this.task.treeData;
      const data = {
        targetUid: this.uid,
        dsAuthInfo: {
          appends: [],
          updates: [],
          deletes: []
        },
        taskAuthInfo: {
          appends: [],
          updates: [],
          deletes: []
        }
      };

      this.datasource.authedTreeData = [];
      this.task.authedTreeData = [];
      const generateAuthData = (type, treeData) => {
        console.log(type, treeData.length);
        if (treeData && treeData.length) {
          treeData.forEach((child) => {
            if (!this.batchMode || (this.batchMode && this.auth.checkedKeys.includes(child.dsId))) {
              const {
                preDsAuthKinds, dsAuthKinds, dsId, levels, authId, preStartTime, preEndTime
              } = child;
              child.resId = dsId;
              child.authKinds = dsAuthKinds;

              let { startTime, endTime } = child;
              if (startTime) {
                startTime = moment(startTime).format('YYYY-MM-DD HH:mm:ss');
              }
              if (endTime) {
                endTime = moment(endTime).format('YYYY-MM-DD HH:mm:ss');
              }
              const levels2 = levels.filter((level) => level !== dsId);
              if (!preDsAuthKinds.length && dsAuthKinds.length) {
                data[type].appends.push({
                  resPaths: levels2, startTime, endTime, resId: dsId, authLabels: dsAuthKinds
                });
                this[this.activeAuthType].authedTreeData.push({
                  action: 'appends',
                  addAuth: dsAuthKinds,
                  ...child
                });
              } else if (preDsAuthKinds.length && !dsAuthKinds.length) {
                data[type].deletes.push({
                  resPaths: levels2,
                  resId: dsId,
                  authId
                });
                this[this.activeAuthType].authedTreeData.push({
                  action: 'deletes',
                  deleteAuth: preDsAuthKinds,
                  ...child
                });
              } else if (child.loaded && (!this.arrayEqual(preDsAuthKinds, dsAuthKinds) || startTime !== preStartTime || endTime !== preEndTime)) {
                console.log('preDsAuthKinds, dsAuthKinds', preDsAuthKinds, dsAuthKinds);
                data[type].updates.push({
                  resPaths: levels2, startTime, endTime, authId, resId: dsId, authLabels: dsAuthKinds
                });
                const addAuth = [];
                const deleteAuth = [];
                preDsAuthKinds.forEach((preDsAuthKind) => {
                  if (!dsAuthKinds.includes(preDsAuthKind)) {
                    deleteAuth.push(preDsAuthKind);
                  }
                });
                dsAuthKinds.forEach((dsAuthKind) => {
                  if (!preDsAuthKinds.includes(dsAuthKind)) {
                    addAuth.push(dsAuthKind);
                  }
                });
                this[this.activeAuthType].authedTreeData.push({
                  action: 'updates', addAuth, deleteAuth, ...child
                });
              }
            }
          });
        }
      };

      if (this.activeAuthTab === 'DataSource') {
        generateAuthData('dsAuthInfo', dsTreeData);
        this.authedData = {
          authKind: this.activeAuthTab,
          targetUid: this.uid,
          appends: data.dsAuthInfo.appends,
          updates: data.dsAuthInfo.updates,
          deletes: data.dsAuthInfo.deletes
        };
      } else if (this.activeAuthTab === 'DataJob') {
        generateAuthData('taskAuthInfo', taskTreeData);
        this.authedData = {
          authKind: this.activeAuthTab,
          targetUid: this.uid,
          appends: data.taskAuthInfo.appends,
          updates: data.taskAuthInfo.updates,
          deletes: data.taskAuthInfo.deletes
        };
      }
    },
    async handlePreview() {
      await this.handleGetPreviewData();
      if (this.datasource.authedTreeData.length || this.task.authedTreeData.length) {
        this.previewMode = true;
        this.isEdit = false;
        this.datasource.stashTreeData = deepClone(this.datasource.treeData);
        this.task.stashTreeData = deepClone(this.task.treeData);
        this.datasource.searchKey = '';
        this.task.searchKey = '';
        this.datasource.treeData = this.datasource.authedTreeData;
        this.task.treeData = this.task.authedTreeData;
        let firstNode = {};
        if (this.activeAuthTab === 'DataSource') {
          firstNode = this.datasource.treeData[0];
          this.$refs.dataSourceTree.setData(this.datasource.treeData);
        } else if (this.activeAuthTab === 'DataJob') {
          firstNode = this.task.treeData[0];
          this.$refs.dataSourceTree.setData(this.task.treeData);
        }
        this.$refs.dataSourceTree.setSelected(firstNode.key, true);
        this[this.activeAuthType].selectedNode = firstNode;
        await this.handleGetAuthTree(firstNode.objType);
      } else {
        this.$Modal.info({
          title: this.$t('ti-shi'),
          content: this.$t('wei-jin-hang-ren-he-shou-quan')
        });
      }
    },
    async handleModifyUserDsAuth() {
      if (this.activeAuthTab === 'DataSource') {
        const res = await this.$services.modifyUserAuth({
          data: this.authedData
        });

        if (res.success) {
          this.$Message.success(this.$t('shu-ju-ku-shou-quan-cheng-gong'));
          this.handleReloadPage();
          if (this.batchMode) {
            this.handleSwitchBatchMode();
          }
          // this.goSubAccountPage();
          //
          // this.authedData = {};
          // this.datasource.authedTreeData = [];
          // this.task.authedTreeData = [];
          // this.datasource.originalTreeData = deepClone(this.datasource.treeData);
          // this.task.originalTreeData = deepClone(this.task.treeData);
        }
      } else if (this.activeAuthTab === 'DataJob') {
        console.log('this.selectedCcCluster.clusterCode', this.selectedCcCluster);
        const res = await modifyUserAuthForCC(this.authedData, { customeHeaders: { 'X-Product-Code': this.selectedCcCluster } });

        if (res.data.success) {
          this.$Message.success(this.$t('ren-wu-shou-quan-cheng-gong'));
          this.handleReloadPage();
          if (this.batchMode) {
            this.handleSwitchBatchMode();
          }
          // this.goSubAccountPage();
          //
          // this.authedData = {};
          // this.datasource.authedTreeData = [];
          // this.task.authedTreeData = [];
          // this.datasource.originalTreeData = deepClone(this.datasource.treeData);
          // this.task.originalTreeData = deepClone(this.task.treeData);
        }
      }
    },
    getNodeByKey(tree, curKey, keyField = 'key', node = null) {
      const stack = [];
      for (const item of tree) {
        if (item) {
          stack.push(item);
          while (stack.length) {
            const temp = stack.pop();

            if (temp[keyField] === curKey) {
              node = temp;
              break;
            }

            const children = temp.children || [];
            for (let i = children.length - 1; i >= 0; i--) {
              stack.push(children[i]);
            }
          }
        }
      }
      return node;
    },
    setCurrentNodeAuthKey(key) {
      const selectedKeys = this.$refs.authTree.getCheckedKeys('parents');
      const dsAuthKinds = [];
      selectedKeys.forEach((selectedKey) => {
        if (typeof selectedKey === 'string') {
          dsAuthKinds.push(selectedKey);
        }
      });
      if (this[this.activeAuthType].selectedNode && !key) {
        const currentNode = this.getNodeByKey(this[this.activeAuthType].treeData, this[this.activeAuthType].selectedNode.key);
        currentNode.dsAuthKinds = dsAuthKinds;
        currentNode.diffuse = this[this.activeAuthType].selectedNode.diffuse;
        currentNode.startTime = this[this.activeAuthType].selectedNode.startTime;
        currentNode.endTime = this[this.activeAuthType].selectedNode.endTime;
      }

      if (this.batchMode && key) {
        const currentNode = this.getNodeByKey(this[this.activeAuthType].treeData, key);
        currentNode.dsAuthKinds = dsAuthKinds;
        currentNode.diffuse = this.auth.diffuse;
        currentNode.startTime = this.auth.startTime;
        currentNode.endTime = this.auth.endTime;
      }
    },
    renderNode(h, node) {
      const {
        title, icon, children, action, dsDeployType, dsType, jobType, isAuthed, enableQuery
      } = node;
      const style = {
        marginLeft: '3px',
        color: '#000',
        fontWeight: `${node.isNew ? 'bold' : 'default'}`
      };

      if (this.previewMode) {
        if (action === 'appends') {
          style.color = 'green';
        }

        if (action === 'deletes') {
          style.color = 'red';
        }

        if (action === 'updates') {
          style.color = 'yellow';
        }
      }
      return (
        <div class="node">
          {icon && <data-source-icon type={dsType} instanceType={dsDeployType} />}
          {this.activeAuthType === 'task' && <i class={`iconfont ${JOB_TYPE_ICON[jobType]}`}></i>}
          <div style={style}>
            {title}
          </div>
          {isAuthed && <span class="authed-tip"></span>}
          {children && children.length > 0
            && <div style="font-weight: bold;color: #bbb;">[{children.length}]</div>}
          {enableQuery && <i style="position: absolute;right:10px" class='iconfont iconkechaxun'></i>}
        </div>
      );
    },
    renderAuthNode(h, node) {
      const {
        i18nName, icon, children, key, isLeaf
      } = node;

      const style = {
        color: '#000'
      };

      if (this[this.activeAuthType].selectedNode) {
        const { addAuth, deleteAuth } = this[this.activeAuthType].selectedNode;

        if (isLeaf && addAuth && addAuth.includes(key)) {
          style.color = 'green';
          style.fontWeight = 'bold';
        }

        if (isLeaf && deleteAuth && deleteAuth.includes(key)) {
          style.color = 'red';
          style.fontWeight = 'bold';
        }
      }

      return (
        <div class="node" style={style}>
          {i18nName}
        </div>
      );
    },
    renderUserNode(h, node) {
      const { title } = node;

      return (
        <div class="node">
          {title}
        </div>
      );
    },
    async handleDataSourceTreeSelect(node) {
      console.log('DataSourceTreeSelect');
      // if (this.batchMode && !this.previewMode) {
      //   await this.handleGetAuthTree(node.objType);
      //   return;
      // }
      const { selectedNode } = this[this.activeAuthType];
      this.$refs.dataSourceTree.setSelected(node.key, true);
      if ((selectedNode && node.key !== selectedNode.key) || !selectedNode) {
        if (selectedNode) {
          this.setCurrentNodeAuthKey();
        }

        const currentNode2 = this.getNodeByKey(this[this.activeAuthType].treeData, node.key);

        console.log('loaded', node.loaded, currentNode2.loaded, this.previewMode);

        if (!currentNode2.loaded && !this.previewMode) {
          let res;
          let isCC = false;
          this.loadingAuth = true;
          if (!this.isEdit && !this.isView) {
            if (this.activeAuthType === 'datasource') {
              res = await this.$services.listMyAuthOfRes({
                data: {
                  authKind: this.activeAuthTab,
                  targetUid: this.uid,
                  groups: [
                    {
                      resId: node.dsId,
                      resPaths: node.levels.filter((level) => level !== node.dsId)
                    }
                  ]
                }
              });
            } else {
              res = await listMyAuthOfResForCC({
                authKind: this.activeAuthTab,
                targetUid: this.uid,
                groups: [
                  {
                    resId: node.dsId,
                    resPaths: node.levels.filter((level) => level !== node.dsId)
                  }
                ]
              }, { customeHeaders: { 'X-Product-Code': this.selectedCcCluster } });
              isCC = true;
            }
          } else {
            if (this.activeAuthType === 'datasource') {
              res = await this.$services.listUserAuthOfRes({
                data: {
                  authKind: this.activeAuthTab,
                  targetUid: this.uid,
                  groups: [
                    {
                      resId: node.dsId,
                      resPaths: node.levels.filter((level) => level !== node.dsId)
                    }
                  ]
                }
              });
            } else {
              res = await listUserAuthOfResForCC({
                authKind: this.activeAuthTab,
                targetUid: this.uid,
                groups: [
                  {
                    resId: node.dsId,
                    resPaths: node.levels.filter((level) => level !== node.dsId)
                  }
                ]
              }, { customeHeaders: { 'X-Product-Code': this.selectedCcCluster } });
              isCC = true;
            }
          }
          currentNode2.loaded = true;
          let ifSuccess = false;
          let resData = [];
          if (isCC) {
            ifSuccess = res.data.success;
            resData = res.data.data;
          } else {
            ifSuccess = res.success;
            resData = res.data;
          }
          if (ifSuccess) {
            if (resData && resData.length) {
              const {
                dsAuthKinds, id, startTime, endTime
              } = resData[0];
              currentNode2.dsAuthKinds = dsAuthKinds;
              currentNode2.preDsAuthKinds = deepClone(dsAuthKinds);
              currentNode2.authId = resData[0].id;
              currentNode2.startTime = startTime;
              currentNode2.endTime = endTime;
              currentNode2.preStartTime = startTime;
              currentNode2.preEndTime = endTime;
              if (startTime) {
                currentNode2.startTime = moment(resData[0].startTime);
              }
              if (endTime) {
                currentNode2.endTime = moment(resData[0].endTime);
              }
            } else {
              currentNode2.preDsAuthKinds = [];
              currentNode2.preStartTime = null;
              currentNode2.preEndTime = null;
            }
          }
          this.loadingAuth = false;
        } else {
          this.loadingAuth = true;
          setTimeout(() => {
            this.loadingAuth = false;
          }, 50);
        }

        this[this.activeAuthType].selectedNode = currentNode2;

        await this.handleGetAuthTree(node.objType);
      }
    },
    async listLevels(node = null, isForce) {
      console.log(node);
      let authedList = [];
      if (this.activeAuthTab === 'DataSource') {
        console.log('this.includesDM', this.includesDM);
        let getDsList = this.$services.listElementsOfLevel;
        if (this.includesDM) {
          getDsList = this.$services.listElementsOfLevelForDM;
        }
        const res = await getDsList({
          data: {
            authKind: this.activeAuthTab,
            resPaths: []
          }
        });
        if (this.isView || this.isEdit || isForce) {
          let res1 = {};
          res1 = await this.$services.listUserAuthRes({
            data: {
              authKind: this.activeAuthTab,
              targetUid: this.uid
            }
          });
          if (res.success && res1.success) {
            res1.data.forEach((authed) => {
              res.data.forEach((resData) => {
                if (authed.resId === resData.objId) {
                  resData.isAuthed = true;
                }
              });
            });
            if (this.isView) {
              authedList = res.data.filter((aItem) => res1.data.find((bItem) => bItem.resId === aItem.objId));
            } else {
              authedList = res.data;
            }
          }
        } else {
          const res1 = await this.$services.listMyAuthRes({
            data: {
              authKind: this.activeAuthTab
            }
          });
          if (res.success && res1.success) {
            authedList = res.data.filter((aItem) => res1.data.find((bItem) => bItem.resId === aItem.objId));
          }
        }
      } else if (this.activeAuthTab === 'DataJob') {
        console.log('this.selectedCcCluster.clusterCode', this.selectedCcCluster);
        const res = await listElementsOfLevelForCC({
          authKind: this.activeAuthTab,
          resPaths: []
        }, { customeHeaders: { 'X-Product-Code': this.selectedCcCluster } });
        if (this.isView || this.isEdit || isForce) {
          const res1 = await listUserAuthResForCC({
            authKind: this.activeAuthTab,
            targetUid: this.uid
          }, { customeHeaders: { 'X-Product-Code': this.selectedCcCluster } });
          if (res.data.success && res1.data.success) {
            res1.data.data.forEach((authed) => {
              res.data.data.forEach((resData) => {
                if (authed.resId === resData.objId) {
                  resData.isAuthed = true;
                }
              });
            });
            if (this.isView) {
              authedList = res.data.data.filter((aItem) => res1.data.data.find((bItem) => bItem.resId === aItem.objId));
            } else {
              authedList = res.data.data;
            }
          }
        } else {
          const res1 = await listMyAuthResForCC({
            authKind: this.activeAuthTab
          }, { customeHeaders: { 'X-Product-Code': this.selectedCcCluster } });
          if (res.data.success && res1.data.success) {
            authedList = res.data.data.filter((aItem) => res1.data.data.find((bItem) => bItem.resId === aItem.objId));
          }
        }
      }

      if (authedList.length) {
        const treeData = [];
        let noIndent = true;
        authedList.forEach((leaf) => {
          const {
            objId, objName, objAttr, objType, objDesc, isAuthed
          } = leaf;
          const item = {
            action: '',
            key: objId,
            dsId: node ? node.dsId : objId,
            title: objDesc ? `${objName}(${objDesc})` : objName,
            levels: node ? [...node.levels, objId] : [objId],
            dsAuthKinds: [],
            preDsAuthKinds: [],
            loaded: false,
            diffuse: false,
            startTime: null,
            endTime: null,
            icon: objType === 'Instance' ? objAttr.dsType : objType,
            objType,
            dsType: objAttr.dsType,
            dsDeployType: objAttr.dsDeployType,
            jobType: objAttr.jobType,
            isAuthed,
            enableQuery: objAttr.enableQuery,
            ...node
          };

          if (!leaf.leaf) {
            noIndent = false;
          }
          treeData.push(item);
        });
        this[this.activeAuthType].originalTreeData = treeData;
        this[this.activeAuthType].treeData = deepClone(treeData);
        await this.$refs.dataSourceTree.setData(treeData);
        if (treeData.length) {
          let selectNode = treeData[0];
          if (this[this.activeAuthType].selectedNode) {
            treeData.forEach((data, index) => {
              if (data.dsId === this[this.activeAuthType].selectedNode.key) {
                selectNode = treeData[index];
              }
            });
          }
          await this.handleDataSourceTreeSelect(selectNode);
        }
        this.noIndent = noIndent;
      } else {
        await this.$refs.dataSourceTree.setData([]);
      }
    },
    async handleGetAuthTree(elementType = 'Instance') {
      if (this.authList[elementType]) {
        this.auth.treeData = deepClone(this.authList[elementType]);
        this.auth.originalTreeData = deepClone(this.authList[elementType]);
      } else {
        const res = await this.$services.fetchAuthTreeDef({
          data: {
            kind: this.activeAuthTab,
            elementType
          }
        });

        if (res.success) {
          this.auth.treeData = res.data;
          this.auth.originalTreeData = deepClone(res.data);
          this.authList[elementType] = deepClone(res.data);
        }
      }

      if (this.batchMode) {
        const selectedAuthKeys = this.$refs.authTree.getCheckedKeys();
        await this.$refs.authTree.setData(this.auth.treeData);
        await this.$refs.authTree.setCheckedKeys(selectedAuthKeys, true);
        return;
      }
      const extraIndex = [];
      this[this.activeAuthType].selectedNode.preDsAuthKinds.forEach((auth, index) => {
        let hasSame = false;
        this.auth.treeData.forEach((data) => {
          if (!hasSame) {
            if (data.key !== auth || data.category) {
              if (data.children && data.children.length > 0) {
                data.children.forEach((children) => {
                  if (!hasSame) {
                    if (children.key === auth && !children.category) {
                      hasSame = true;
                    } else {
                      if (children.children && children.children.length > 0) {
                        if (!hasSame) {
                          children.children.forEach((children2) => {
                            if (!hasSame) {
                              if (children2.key === auth && !children2.category) {
                                hasSame = true;
                              }
                            }
                          });
                        }
                      }
                    }
                  }
                });
              }
              // this.differAuth(data.children, auth, hasSame);
            } else {
              hasSame = true;
            }
          }
        });
        if (!hasSame) {
          extraIndex.push(index);
          // this[this.activeAuthType].selectedNode.preDsAuthKinds.splice(index, 1);
        }
      });
      this[this.activeAuthType].selectedNode.preDsAuthKinds = this[this.activeAuthType].selectedNode.preDsAuthKinds.filter((element, index) => !extraIndex.includes(index));
      const setChecked = (treeData) => {
        if (treeData && treeData.length) {
          treeData.forEach((child) => {
            if (this[this.activeAuthType].selectedNode.dsAuthKinds.includes(child.key)) {
              child.checked = true;
            }
            child.disabled = !this.isEdit || this.previewMode;
            if (child && child.children) {
              setChecked(child.children);
            }
          });
        }
      };

      if (this.auth.treeData && this.auth.treeData) {
        setChecked(this.auth.treeData);
      }

      await this.$refs.authTree.setData(this.auth.treeData);
    },
    // differAuth(children, auth, hasSame) {
    //   console.log('childre', children, auth, hasSame);
    //   if (!hasSame) {
    //     children.forEach((data) => {
    //       console.log(2, data.key, auth);
    //       if (data.key === auth) {
    //         hasSame = true;
    //       } else {
    //         if (data.children && data.children.length > 0) {
    //           this.differAuth(data.children, auth, hasSame);
    //         }
    //       }
    //     });
    //   }
    // },
    async handleSearchTypeChange(value) {
      let authedList = [];
      if (value !== 'all') {
        let res = {};
        if (this.activeAuthTab === 'DataSource') {
          res = await this.$services.listUserAuthRes({
            data: {
              authKind: this.activeAuthTab,
              targetUid: this.uid
            }
          });
          if (res.success) {
            if (value === 'authed') {
              authedList = this[this.activeAuthType].originalTreeData.filter((aItem) => res.data.find((bItem) => bItem.resId === aItem.dsId));
            } else {
              authedList = this[this.activeAuthType].originalTreeData.filter((aItem) => res.data.every((bItem) => bItem.resId !== aItem.dsId));
            }
            this.$refs.dataSourceTree.setData(authedList);
            if (authedList.length) {
              const selectNode = authedList[0];
              this.handleDataSourceTreeSelect(selectNode);
            }
          }
        } else if (this.activeAuthTab === 'DataJob') {
          res = await listUserAuthResForCC({
            authKind: this.activeAuthTab,
            targetUid: this.uid
          }, { customeHeaders: { 'X-Product-Code': this.selectedCcCluster } });
          if (res.data.success) {
            if (value === 'authed') {
              authedList = this[this.activeAuthType].originalTreeData.filter((aItem) => res.data.data.find((bItem) => bItem.resId === aItem.dsId));
            } else {
              authedList = this[this.activeAuthType].originalTreeData.filter((aItem) => res.data.data.every((bItem) => bItem.resId !== aItem.dsId));
            }
            this.$refs.dataSourceTree.setData(authedList);
            if (authedList.length) {
              const selectNode = authedList[0];
              this.handleDataSourceTreeSelect(selectNode);
            }
          }
        }
      } else {
        this.$refs.dataSourceTree.setData(this[this.activeAuthType].originalTreeData);
        if (this[this.activeAuthType].originalTreeData.length) {
          const selectNode = this[this.activeAuthType].originalTreeData[0];
          this.handleDataSourceTreeSelect(selectNode);
        }
      }
    },
    async handleSwitchAuth(value, type) {
      if (this.activeAuthTab === value) {
        return;
      }
      this.datasourceTreeSearchType = 'all';
      await this.handleGetPreviewData();
      if (this.authedData.appends && this.authedData.appends.length === 0 && this.authedData.deletes.length === 0 && this.authedData.updates.length === 0) {
        this.activeAuthTab = value;
        this.activeAuthType = type;
        this.datasource.selectedNode = null;
        this.task.selectedNode = null;
        this.auth = {
          checkedKeys: [],
          startTime: null,
          endTime: null,
          originalTreeData: [],
          batchTreeData: [],
          diffuse: false,
          treeData: [],
          searchKey: '',
          loading: false
        };
        if (this.activeAuthTab === 'DataJob' && this.getCcProductClusterList.length > 0) {
          this.selectedCcCluster = this.getCcProductClusterList[0].clusterCode;
        }
        await this.listLevels();

        this.authedData = [];
        this.datasource.searchKey = '';
        this.task.searchKey = '';
      } else {
        this.$Modal.confirm({
          title: this.$t('zi-yuan-shou-quan-ti-shi'),
          content: this.$t('dang-qian-lei-xing-de-zi-yuan-you-wei-ti-jiao-de-shou-quan-qing-ti-jiao-hou-zai-qie-huan-zi-yuan-lei-xing-ru-xuan-ze-hu-lve-bing-ji-xu-ben-ci-bian-geng-jiang-qing-kong'),
          onText: this.$t('guan-bi'),
          cancelText: this.$t('hu-lve-bing-ji-xu'),
          onCancel: () => {
            this.datasource.authedTreeData = [];
            this.task.authedTreeData = [];

            this.authedData = [];
            this.datasource.searchKey = '';
            this.task.searchKey = '';
            this.activeAuthTab = value;
            this.activeAuthType = type;
            this.datasource.selectedNode = null;
            this.task.selectedNode = null;
            this.auth = {
              checkedKeys: [],
              startTime: null,
              endTime: null,
              originalTreeData: [],
              batchTreeData: [],
              diffuse: false,
              treeData: [],
              searchKey: '',
              loading: false
            };
            this.listLevels();
          }
        });
      }
    },
    handleChangeCcCluster(data) {
      this.handleGetPreviewData();
      if (this.authedData.appends && this.authedData.appends.length === 0 && this.authedData.deletes.length === 0 && this.authedData.updates.length === 0) {
        this.selectedCcCluster = data;
        this.datasource.selectedNode = null;
        this.task.selectedNode = null;
        this.auth = {
          checkedKeys: [],
          startTime: null,
          endTime: null,
          originalTreeData: [],
          batchTreeData: [],
          diffuse: false,
          treeData: [],
          searchKey: '',
          loading: false
        };
        this.listLevels();
      } else {
        this.$Modal.confirm({
          title: this.$t('zi-yuan-shou-quan-ti-shi'),
          content: this.$t('dang-qian-chan-pin-ji-qun-xia-you-wei-ti-jiao-de-shou-quan-qing-ti-jiao-hou-zai-qie-huan-chan-pin-ji-qun-ru-xuan-ze-hu-lve-bing-ji-xu-ben-ci-bian-geng-jiang-qing-kong'),
          onText: this.$t('guan-bi'),
          cancelText: this.$t('hu-lve-bing-ji-xu'),
          onCancel: () => {
            this.datasource.authedTreeData = [];
            this.task.authedTreeData = [];

            this.authedData = [];
            this.selectedCcCluster = data;
            this.datasource.searchKey = '';
            this.task.searchKey = '';
            this.datasource.selectedNode = null;
            this.task.selectedNode = null;
            this.auth = {
              checkedKeys: [],
              startTime: null,
              endTime: null,
              originalTreeData: [],
              batchTreeData: [],
              diffuse: false,
              treeData: [],
              searchKey: '',
              loading: false
            };
            this.listLevels();
          }
        });
      }
    },
    handleDataSourceSearch() {
      const filterTreeData = this[this.activeAuthType].treeData.filter((data) => data.title.includes(this.datasourceTreeSearchKey));
      this.$refs.dataSourceTree.setData(filterTreeData);
      if (filterTreeData.length) {
        const selectNode = filterTreeData[0];
        this.handleDataSourceTreeSelect(selectNode);
      }
    },
    handleAuthSearch() {
      this.$refs.authTree.filter(this.auth.searchKey);
    },
    handleUserSearch() {
      this.$refs.userTree.filter(this.subAccount.searchKey);
    },
    handleStartTimeChange() {
      this.selectedRange = {};
    },
    handleEndTimeChange() {
      this.selectedRange = {};
    },
    handleRangeChange(range) {
      this.selectedRange = range;
      if (this.batchMode) {
        this.auth.startTime = range.startTime;
        this.auth.endTime = range.endTime;
      } else {
        this[this.activeAuthType].selectedNode.startTime = range.startTime;
        this[this.activeAuthType].selectedNode.endTime = range.endTime;
      }
    },
    disabledStartDate(startValue) {
      const endValue = this.endTime;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > endValue.valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.startTime;
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    moment,
    async handleSwitchBatchMode(needSwitch = true) {
      this.$refs.dataSourceTree.setSelected('test', true);
      this.$refs.dataSourceTree.clearChecked();
      this.auth = {
        checkedKeys: [],
        startTime: null,
        endTime: null,
        originalTreeData: [],
        batchTreeData: [],
        diffuse: false,
        treeData: [],
        searchKey: '',
        loading: false
      };

      this.datasource.selectedNode = null;
      this.task.selectedNode = null;
      if (needSwitch) {
        this.batchMode = !this.batchMode;
      }
      if (this.batchMode) {
        if (this.activeAuthType === 'task') {
          await this.handleGetAuthTree('DataJob');
          console.log('set task');
          this.auth.batchTreeData = deepClone(this.authList.DataJob);
        }
        if (this.activeAuthType === 'datasource') {
          await this.handleGetAuthTree('Instance');
          this.auth.batchTreeData = deepClone(this.authList.Instance);
        }
        this.$refs.authTree.setData(this.auth.batchTreeData);
      } else {
        this.handleReloadPage();
      }
    },
    goSubAccountPage() {
      this.$router.push({ name: 'System_Sub_Account' });
    },
    handleGoAuth() {
      this.$router.push({ path: `/system/sub_account/auth/${this.uid}?name=${this.subAccount}&&type=edit` });
    }
  }
};
</script>

<style scoped lang="less">
.auth-container-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .auth-content {
    height: 100%;

    .users {
      width: 300px;
      height: 100%;
      display: flex;
      flex-direction: column;

      .user-tree {
        border: 1px solid #ccc;
        border-top: none;
        border-right: none;
        flex: 1;
      }
    }

    .auth-container {
      flex: 1;
      min-width: 0;
      display: flex;
      flex-direction: column;
      height: 100%;

      .auth {
        display: flex;
        width: 100%;
        flex: 1;
        min-height: 0;
        height: 100%;

        .left {
          height: 100%;
          width: 460px;
          min-height: 0;
          display: flex;
          flex-direction: column;

          .search {
            display: flex;
          }

          .auth-btns {
            width: 100%;
            display: flex;

            .auth-btn {
              flex: 1;
            }
          }

          /deep/ .search .ant-input {
            border-top: none;
          }

          .datasource-tree {
            flex: 1;
            min-height: 0;
            border: 1px solid #ccc;
            border-top: none;
          }

          .no-indent {
            /deep/ .ctree-tree-node__square:first-child {
              display: none;
            }
          }
        }

        .middle {
          flex: 1;
          min-height: 0;
          display: flex;
          flex-direction: column;

          &.no-auth {
            border: 1px solid #ccc;
            border-left: none;
          }

          .setting {
            padding: 10px;
            border: 1px solid #ccc;
            border-bottom: none;
            border-left: none;

            section {
              display: flex;
              align-items: center;

              .label {
                margin-right: 10px;
              }

              .ranges {
                margin-bottom: 5px;
              }
            }
          }

          .auth-tree-container {
            display: flex;
            flex: 1;
            min-height: 0;
            flex-direction: column;
            position: relative;

            .auth-loading {
              position: absolute;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center; /* 垂直居中 */
              justify-content: center; /* 水平居中，如果需要的话 */
              left: 0;
              top: 0;
              z-index: 999;
              background: rgba(255,255,255,0.8);
            }

            /deep/ .search .ant-input {
              border-left: none;
            }

            .auth-tree {
              flex: 1;
              min-height: 0;
              border: 1px solid #ccc;
              border-top: none;
              border-left: none;
            }
          }
        }

        .right {

        }

        .tree {
          display: flex;
          flex-direction: column;
        }
      }

      .footer {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

/deep/ .node {
  display: flex;
  align-items: center;
}
</style>
